// Per Facebook's instructions (https://developers.facebook.com/docs/sharing/webmasters/images/),
// we still make our old careers image accessible:
import '../../images/www/meta/careers.jpg';
import { Location } from 'history';

import { IHeadData } from '../../resources/types/head_data';

import careersImage from '../../images/www/meta/careers-2.png';
import warehouseImage from '../../images/www/meta/company.jpg';
import windowsSocialImage from '../../images/www/windows_social.jpg';

const BASE_KEYWORDS: string[] = [
  'Clutter',
  'Storage',
  'Moving',
  'Pickup',
  'Return',
  'Los Angeles',
  'San Francisco',
  'San Diego',
  'Orange County',
  'New York',
  'Seattle',
  'Chicago',
  'Philadelphia',
];

const HOME_KEYWORDS: string[] = [
  'Moving and Storage Company',
  'Moving and Storage Units',
  'Storage Unit Moving Company',
  'Movers to Storage Unit',
  'Moving Storage Facilities',
  'Storage and Moving Units',
  'Advanced Moving and Storage',
  'Affordable Moving & Storage',
  'Professional Moving & Storage',
  'Moving & Storage Solutions',
  'Moving and Storage Solutions',
  'Advantage Moving and Storage',
  'Moving Storage Solutions',
];

const SERVICE_MOVING_KEYWORDS: string[] = [
  'Moving Company',
  'Moving Services',
  'Commercial Mover Company',
  'Moving Containers Services',
  'Container Moving Company',
  'Movers Services',
  'Hourly Moving Service',
  'Moving Delivery Service',
  'Movers Same Day Service',
  'Container Moving Solutions',
];

const SERVICE_STORAGE_KEYWORDS: string[] = [
  'Storage Units',
  'Storage Solutions',
  'Portable Storage Units',
  'Portable Self Storage Units',
  'Portable Self Storage Containers',
  'Storage Company',
  'Storage Service',
  'Storage Container Company',
  'Storage Unit with Pickup Service',
  'Storage Pickup and Delivery',
  'Storage Pick Up Service',
  'Storage for Warehouse',
];

const SERVICE_MOVING_WITH_STORAGE_KEYWORDS: string[] = [
  'Moving and Storage Containers',
  'Moving and Storage Services',
  'Storage with Movers',
  'Storage and Moving Services',
  'Moving & Storage Services',
  'Moving Storage Services',
  'Packing and Storage Services',
  'Storage Moving Services',
];

const BOOK_STORAGE_KEYWORDS: string[] = [
  'Portable Storage Containers for Rent',
  'Portable Storage Units for Rent',
  'Rent Portable Storage',
  'Storage Unit Quote',
  'Cost Of Portable Storage Units',
  'Portable Storage Units Prices',
  'Storage Quotes',
  'Quote Storage',
  'Storage Quote',
  'Book Storage Online',
];

const BOOK_MOVING_KEYWORDS: string[] = [
  'Moving in Quote',
  'Moving Quotes',
  'Moving Quote',
  'Cost for Movers Cross Country',
  'Shipping Container Moving Cost',
  'Book Movers Online',
  'Moving Container Quotes',
  'Movers Book Online',
];

const KEYWORDS_META = {
  name: 'keywords',
  content: BASE_KEYWORDS.join(', '),
};

const DEFAULT_HEAD_DATA = { title: 'Clutter', meta: [] };

const imgUrl = (path: string) => `https:${path}`;

const HEAD_DATA: { [path: string]: IHeadData } = {
  '/': {
    title: 'Clutter | Moving & Storage',
    meta: [
      {
        name: 'keywords',
        content: BASE_KEYWORDS.concat(HOME_KEYWORDS).join(', '),
      },
      {
        property: 'og:image',
        content: imgUrl(windowsSocialImage),
      },
      {
        name: 'description',
        content:
          'Save on modern moving and storage with Clutter, the moving and storage company. We provide customizable solutions for all, including warehouse storage, self-storage, moving, and packing supplies.',
      },
      {
        property: 'og:description',
        content:
          'Save on modern moving and storage with Clutter, the moving and storage company. We provide customizable solutions for all, including warehouse storage, self-storage, moving, and packing supplies.',
      },
      {
        property: 'og:title',
        content: 'Clutter | Moving & Storage',
      },
    ],
  },
  '/getstarted': {
    title: 'Clutter Storage - We Pick It Up, Store It and Bring It Back',
    meta: [
      {
        property: 'robots',
        content: 'noindex,nofollow',
      },
    ],
  },
  '/help': {
    title: 'Clutter | Help',
    meta: [
      KEYWORDS_META,
      {
        property: 'og:image',
        content: imgUrl(windowsSocialImage),
      },
      {
        name: 'description',
        content:
          'How does Clutter work? It’s simple. We come to you, pack and pick up your stuff, then take it ' +
          'to our storage facility. Need something back? We deliver it back whenever, wherever.',
      },
      {
        property: 'og:description',
        content:
          'Clutter is a convenient, local storage solution that provides free item pick up and delivery. ' +
          'It’s the simplest way to store what matters to you.',
      },
      {
        property: 'og:title',
        content: 'Clutter | Help',
      },
    ],
  },
  '/locations': {
    title: 'Clutter | Locations',
    meta: [
      KEYWORDS_META,
      {
        property: 'og:image',
        content: imgUrl(windowsSocialImage),
      },
      {
        name: 'description',
        content:
          'Moving & storage available nationwide. Bay Area. Chicago. Los Angeles. New Jersey. New York. ' +
          'Philadelphia. Orange County. San Diego. Seattle. Inland Empire.',
      },
      {
        property: 'og:description',
        content:
          'Moving & storage available nationwide. Bay Area. Chicago. Los Angeles. New Jersey. New York. ' +
          'Philadelphia. Orange County. San Diego. Seattle. Inland Empire.',
      },
      {
        property: 'og:title',
        content: 'Clutter | Locations',
      },
    ],
  },
  '/company': {
    title: 'Clutter | Company',
    meta: [
      {
        property: 'og:image',
        content: imgUrl(warehouseImage),
      },
      {
        name: 'description',
        content:
          'Come work with us! Learn more about the company and explore open roles.',
      },
      {
        property: 'og:description',
        content:
          'Come work with us! Learn more about the company and explore open roles.',
      },
      {
        property: 'og:title',
        content: 'Clutter | Company',
      },
    ],
  },
  '/legal': {
    title: 'Clutter | Legal',
    meta: [
      {
        property: 'og:title',
        content: 'Clutter | Legal',
      },
      {
        property: 'og:image',
        content: imgUrl(windowsSocialImage),
      },
    ],
  },
  '/careers': {
    title: 'Clutter | Careers',
    meta: [
      {
        property: 'og:description',
        content:
          'Clutter is an on-demand, technology company that empowers you to manage your physical belongings. ' +
          'We’re looking for exceptional people to join our team!',
      },
      {
        name: 'description',
        content:
          'Clutter is an on-demand, technology company that empowers you to manage your physical belongings. ' +
          'We’re looking for exceptional people to join our team!',
      },
      {
        property: 'og:image',
        content: imgUrl(careersImage),
      },
      {
        property: 'og:title',
        content: 'Clutter | Careers',
      },
    ],
  },
  '/services/storage/warehouse-storage': {
    title: 'Clutter | Smart Storage',
    meta: [
      {
        name: 'keywords',
        content: BASE_KEYWORDS.concat(SERVICE_STORAGE_KEYWORDS).join(', '),
      },
      {
        name: 'description',
        content: `Secured storage in warehouse with local pickup option. Explore Clutter’s storage services and solutions, including containers, for hassle-free storage.`,
      },
    ],
  },
  '/services/storage/door-to-door': {
    title: 'Clutter | Door to Door Storage',
    meta: [
      {
        name: 'keywords',
        content: BASE_KEYWORDS.concat(SERVICE_STORAGE_KEYWORDS).join(', '),
      },
      {
        name: 'description',
        content: `We’ll pick up, store & deliver your items from our secure warehouse. From there, enjoy easy online access to your belongings. Get a quote today for your effortless move-in.`,
      },
    ],
  },
  '/services/storage/self-storage': {
    title: 'Clutter | Self Storage',
    meta: [
      {
        name: 'keywords',
        content: BASE_KEYWORDS.concat(SERVICE_STORAGE_KEYWORDS).join(', '),
      },
      {
        name: 'description',
        content:
          'Get in-person access at one of our local storage facilities, with the same excellent service you expect from Clutter. Check our available units online in minutes!',
      },
    ],
  },
  '/services/moving': {
    title: 'Clutter | Moving',
    meta: [
      {
        name: 'keywords',
        content: BASE_KEYWORDS.concat(SERVICE_MOVING_KEYWORDS).join(', '),
      },
      {
        name: 'description',
        content:
          'Experience stress-free moving and packing with transparent prices. Get a quote online in minutes to book your move with Clutter, the moving services company.',
      },
    ],
  },
  '/services/moving/with-storage': {
    title: 'Clutter | Moving with Storage',
    meta: [
      {
        name: 'keywords',
        content: BASE_KEYWORDS.concat(
          SERVICE_MOVING_WITH_STORAGE_KEYWORDS,
        ).join(', '),
      },
    ],
  },
  '/book/moving': {
    title: 'Clutter | Book Moving',
    meta: [
      {
        name: 'keywords',
        content: BASE_KEYWORDS.concat(BOOK_MOVING_KEYWORDS).join(', '),
      },
    ],
  },
  '/book/storage': {
    title: 'Clutter | Book Storage',
    meta: [
      {
        name: 'keywords',
        content: BASE_KEYWORDS.concat(BOOK_STORAGE_KEYWORDS).join(', '),
      },
    ],
  },
};

HEAD_DATA[''] = HEAD_DATA['/']; // slash is missing for root in SSR
HEAD_DATA['/legal/tos'] = HEAD_DATA['/legal'];
HEAD_DATA['/legal/privacy_policy'] = HEAD_DATA['/legal'];
HEAD_DATA['/legal/california_privacy_rights'] = HEAD_DATA['/legal'];
HEAD_DATA['/legal/limited_security_warranty'] = HEAD_DATA['/legal'];

export const resolveHeadData = (location: Location) => {
  const headData = HEAD_DATA[location.pathname];

  if (headData) {
    return {
      ...headData,
      meta: headData.meta || [],
      link: headData.link || [],
    };
  } else {
    return DEFAULT_HEAD_DATA;
  }
};
